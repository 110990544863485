
  function splitSuroundedBy(str:string, chr:string, className:string){
    const result = [];
    let active: boolean =false;
    let last: number=0;
    for (let i = 0; i < str.length; i++) {
      if (str[i] ===chr){
        
        if (active) {
          result.push(<span key={i} className={className}>{str.slice(last,i)}</span>)
          active=false;
        }
        else{
          result.push(str.slice(last,i))
          active=true;
        }
        last=i+1;
      }
    }
    result.push(str.slice(last,str.length))
    return result;
  }

export default function preprocessText(str:string|any, t?:any){
    if (str && typeof(str)==="string"){
      let subresult =  splitSuroundedBy(t?t(str):str,"*","underline");
      
      let result= []
      for (let i = 0; i < subresult.length; i++) {
        if (typeof(subresult[i])==="string"){
          let currentStr = String(subresult[i]);
          let nlIndex = currentStr.indexOf("/");
          if (nlIndex>0){
            
            let previousIndex=-1;
            while(nlIndex>0){
              
              result.push(currentStr.slice(previousIndex+1,nlIndex));
              result.push(<br key={i+nlIndex*100}/>);
              previousIndex=nlIndex;
              nlIndex = currentStr.indexOf("/",nlIndex+1);
            }
            result.push(currentStr.slice(previousIndex+1));
          }
          else{
            result.push(subresult[i]);
          }
        }else{
          result.push(subresult[i])
        }
      }
      
      return (<span>{result}</span>);
    }
    else {
      return str;
    }
  }