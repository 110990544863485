import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import './App.css';
import { Grommet, ResponsiveContext } from 'grommet'
import Router from "./router";
import i18n from "./translation";
import { AuthProvider } from "./auth/AuthProvider";
import { useContext } from "react";

const theme={
  global: {
    colors: {
      // Overriding existing grommet colors
      brand: "#b337b3"
    }
  },
  carousel:{
    icons:{color:"#b336b3"},
    disabled:{icons:{color:"transparent"}}},
  
};

const App = () => {
  const size = useContext(ResponsiveContext);

  return(
  <div id="app" className="app" style={{fontSize:size=="small"?"12pt":"14px"}}>
    <div id = "top"/>
    <AuthProvider>
      <Grommet theme={theme}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Router />
        </I18nextProvider>
      </BrowserRouter>
      </Grommet>
    </AuthProvider>
  </div>
)};

ReactDOM.render(<App />, document.getElementById("root"));
