
import { VscCommentDiscussion } from 'react-icons/vsc';
import { Box, Text } from "grommet";


const Logo = ({size, iconBackground, textColor, pad}) => {
    let coef=1;
    if(size==="medium"){
        coef=1;

    }
    else if (size==="large"){
        coef=4;

    }
    else if (size==="small"){
        coef=0.5;
    }

    size=80*coef;
if (iconBackground){
    return (
        <Box>
            <img src='/img/svg/logo_color.svg' height={size+"px"}/>
        </Box>
    )
}
else{
    return (
        <Box style={textColor?{color:textColor}:null}>
            <img src='/img/svg/logo.svg' height={size+"px"}/>
        </Box>
    )
}


//     let round=17;
//     let logoPadding = 8;
//     let logoSize=30;
//     let textSize=20;
//     let coef=1;
//     if(size==="medium"){
//         coef=1.5;
//         //round=round*3
//         textSize=textSize*coef/1.5;
//     }
//     else if (size==="large"){
//         coef=3;
//         //round=round*3
//         textSize=textSize*coef/2.6;
//     }
//     else if (size==="small"){
//         logoSize=logoSize*1.2;
//         coef=0.7;
//         textSize=textSize;
//     }
//     round=round*coef;
//     logoPadding=logoPadding*coef;
//     logoSize=logoSize*coef;
//     textSize=textSize*coef;

//     let textMarginBottom=logoPadding*1.0;
//     if (size==="small"){
//         textSize=textSize*1.3
//         textMarginBottom=((logoSize)/2) - (iconBackground?6:10);
//     }
//     if (!iconBackground){
//         logoPadding=logoPadding/2;
//     }
    
//     const  gradient="linear-gradient(213deg, rgb(243 136 40 / 84%) 0%, rgb(179 54 179 / 93%) 120%)";

// return (
//     <Box direction="row" align='end' justify='center' className='logo nohover' gap={logoPadding/2+"px"} pad={pad}>
        
//         <Box
//         round={round+"px"} 
//         style={{ 
//             transition: "all .2s linear",
//             padding: logoPadding+"px", 
//             paddingTop: (logoPadding*1.1)+"px",
//             paddingBottom: (logoPadding*0.9)+"px",
//             backgroundImage: ( iconBackground?gradient:undefined )
//             }}  >
//             <VscCommentDiscussion size={logoSize+"px"} style={{ transition: "all .2s linear"}} color={iconBackground?"white":textColor} />
//         </Box>
        
//         <Text weight={800} style={{fontFamily:"Helvetica", marginBottom:textMarginBottom+"px", transition: "all .2s linear", fontSize:textSize+"px" }} color={textColor}>commentie</Text>
//     </Box>
//)
};
Logo.defaultProps={size:"medium",iconBackground:true, textColor:"#252022", pad:"5px"}
export { Logo };