import { useState,useEffect,useMemo } from "react";
import { useLocation,useNavigate } from 'react-router-dom';
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  NotHiddenMin,
  Menu,
  CustomNavLinkSmall,
  CustomNavLinkButton,
  Label,
  Outline,
  Span,
} from "./styles";
import { Logo } from "../Logo/logo";
import { useAuth } from "../../auth/AuthProvider";
import { analytics } from "../../auth/firebase";
import { logEvent } from "firebase/analytics";
import { Box, Text } from "grommet";
import { FaUser } from "react-icons/fa";


// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);
  const location = useLocation();
  const _navigate = useNavigate();
  const navigate = (target:any)=>{
    window.scrollTo(0,0)
    _navigate(target)
  }
  let query = useQuery();
  const { currentUser } = useAuth();

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const [isShrunk, setShrunk] = useState(false);

  const scrollTo = (id: string) => {
    if (location.pathname!==""){
      navigate("../#"+id);
      //window.location.href="/";
    }

    const element = document.getElementById(id) as HTMLDivElement;
    if (element){

      element.scrollIntoView({
        behavior: "smooth",
      });
      
      setVisibility(false);
    }
    else{
      setTimeout(()=>{
        scrollTo(id)
      },100)
    }
  };

  
  useEffect(()=>{

    logEvent(analytics,"navigation",{"pathName":location.pathname, "hash":location.hash, "query":query.toString()})
  },[location,query]);
  

 
  useEffect(() => {
    const handler = (e:any) => {

      setShrunk((isShrunk) => {
        
        if (
          !isShrunk &&
          (document.body.scrollTop > 30 ||
            e.target.scrollTop > 30)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 1 &&
          e.target.scrollTop < 10
          
        ) {          

          e.target.scrollTo({
            top: 0,
            // behavior: 'smooth',
          });
          // document.body.scrollTo({
          //   top: 0,
          //   // behavior: 'smooth',
          // });
          return false;
        }

        return isShrunk;
      });
    };

    if (location.pathname!=="/")
        return setShrunk(true);
    const appDiv = document.getElementById("app");
    appDiv && appDiv.addEventListener("scroll", handler);
    return () => {
        appDiv && appDiv.removeEventListener("scroll", handler)}
        ;
  }, []);



  const SectionsMenu = () => {
    


    return (
      <>
        <CustomNavLinkSmall onClick={() =>  scrollTo("how-it-works")}>
        <Span>{t("How it works")}</Span>
        </CustomNavLinkSmall>
        {/* <CustomNavLinkSmall onClick={() => scrollTo("pricing")}>
          <Span>{t("Pricing")}</Span>
        </CustomNavLinkSmall> */}
        <CustomNavLinkSmall onClick={() =>navigate("/product/labeling")}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => navigate("/pricing")}>
          <Span>{t("Pricing")}</Span>
        </CustomNavLinkSmall>
        {/* <CustomNavLinkSmall onClick={() => scrollTo("contact")}>
          <Span>{t("Docs")}</Span>
        </CustomNavLinkSmall> */}
        <CustomNavLinkSmall onClick={() => scrollTo("contact")}>
          <Span>{t("Contact")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => window.location.href="https://docs.labelator.io"}>
          <Span>{t("Docs")}</Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  const PrimaryMenu = () => {
    //Will be present on the page even for smaller screens


    return (
      <>

      
        
            <CustomNavLinkButton>
              <Box style={{ width: "120px" }} border round pad="5px" onClick={()=>{
                  navigate("/demoLogin")
                }
                  }>
                <Text weight={800} color="brand">Live demo</Text>
              </Box>
            </CustomNavLinkButton>

          {currentUser && currentUser.isVerified ? (
            <CustomNavLinkButton
            onClick={() => scrollTo("signUp")}
          >
            <Span>{`Hi ${currentUser.displayName ||currentUser.email }👋`}</Span>
            </CustomNavLinkButton>
          ):(
            <CustomNavLinkButton >
            <Box  style={{ width: "180px" }}
            onClick={() => scrollTo("signUp")}>
              <Button>{t("Get started")}</Button>
            </Box>
              </CustomNavLinkButton>
          
              )}


          <CustomNavLinkButton >
          <Box focusIndicator={false} direction="row" justify="center" gap="small"  onClick={()=>{
           document.location.href="https://app.labelator.io/login"
          }
            }>
           <Span>Sign in <FaUser/> </Span>
          </Box>
        </CustomNavLinkButton>

      </>
    );
  };

  return (
    <HeaderSection className={isShrunk?"headerSmall":"headerFull"}>
      <Box pad="0px 30px">
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            
            <div className={"smallLogo"} onClick={() => scrollTo("top")}>
                <Logo pad="10px" size="small" />
              </div>
          </LogoContainer>
          <Box direction="row">
          <NotHidden>
            <SectionsMenu />
          </NotHidden>
          <NotHiddenMin>
            <PrimaryMenu />
          </NotHiddenMin>
         
            <Burger onClick={showDrawer}>
              <Outline />
            </Burger>
          
          </Box>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <Box fill  justify="between">
        <Box>
          <SectionsMenu />
        </Box>
        <Box  align="center">
          <PrimaryMenu />
        </Box>
          </Box>
        </Drawer>
      </Box>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
