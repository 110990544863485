import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import {Carousel,Box, Text} from "grommet";
import { Fade } from "react-awesome-reveal";

import {MdOutlineNavigateNext} from "react-icons/md"

import { logEvent } from "firebase/analytics";

import {
  PriceBoxSection,
} from "./styles";
import { BsCheck } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";


const PriceBox = ({
  name,
  features,
  price,
  onSelected,
  isSelected,
  enabled=true,
  onContinue
  
}: {
  name:string,
  features:string[],
  price:string,
  isSelected?:boolean
  onSelected?:(state:boolean)=>any
  enabled?:boolean
  onContinue?:()=>any
  
}) => {

  
  

  const [seleceted,_setSelected] =useState(false);

  const setSelected=()=>{
    if (enabled){
    _setSelected(!seleceted)
    onSelected && onSelected(!seleceted)
    }
  }

  useEffect(()=>{
    _setSelected(isSelected?true:false)
  },[isSelected])


  const body=(
    <Box pad="0px" fill gap="medium" onClick={enabled?setSelected:undefined} focusIndicator={false}>
      
      <Box style={{borderBottom:"2px  solid gray"}}  margin="0px -50px 0px -50px" width="400px" pad="10px 0px" justify="start" align="center" alignSelf="center" flex="grow" >
        <Text weight={900} size="20pt">{name}</Text>
      

      </Box>

      <Box align="start" justify="start"  height="350px" width="280px" gap="10px">
      {features.map((feature,i)=>(
        <Box key={i} direction="row" align="start" justify="start"  > 
        <Box width="30px" flex="grow" align="center">{feature.startsWith("-")?<GrFormClose/>:<BsCheck size="25px"/>}</Box>
        <Text textAlign="start" weight={800} size="12pt">{feature.startsWith("-")?feature.slice(1):feature} </Text> </Box>
      ))}
      </Box>
      <Box style={{borderTop:"2px solid gray", height:!seleceted?"65px":"85px"}} pad="5px 0px 0px"  height="60px" flex="grow" gap="small" justify="center" align="center">
        <Box border round  justify="end" pad="5px 20px" background={seleceted?"brand":undefined} >
        <Text weight={900} size="15pt">{price} </Text>

        </Box>
        {seleceted&&(<Box background="brand" round pad="2px 15px" onClick={(e)=> {
          e.stopPropagation()
          onContinue && onContinue()
          }}>
          Continue
          </Box>
           )}
      </Box>
    </Box>
  )

  return (
  
       
    <PriceBoxSection style={!enabled?{opacity:"60%"} :(seleceted?{
      boxShadow: "0 0 25px rgba(200,100,50,0.9)",
      background:"rgba(250,230,200,0.2)",

      padding:"15px 5px"
      }:undefined)}  >
       {body}
    </PriceBoxSection>
    
      
  );
};

export default withTranslation()(PriceBox);
