import { lazy, useState, useEffect } from "react";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { auth } from "../../auth/firebase";
import Input from "../../common/Input";
import { Button } from "../../common/Button";
import {Box,Text} from "grommet";
import { useAuth } from "../../auth/AuthProvider";
import { doc, setDoc } from "firebase/firestore"; 
import { db } from "../../auth/firebase";



const Contact = lazy(() => import("../../components/ContactForm"));
const MiddleBlock = lazy(() => import("../../components/MiddleBlock"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../../components/ContentBlock"));

const FinishSignUp = () => {
  const OK_STATE="OK";
  const ERR_STATE="ERR"
  const PROMPT_STATE="PROPMPT";
  const [errMessge, setErrMessage] = useState(undefined);
  const [stateType, setStateType] = useState(undefined);
  const [emailFieldValue, setEmailFieldValue] = useState("");
  const {currentUser} = useAuth();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

useEffect(()=>{
  if (currentUser){
    setStateType(OK_STATE);
  }
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem('emailForSignIn');
    if (!email){

      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams.has("email"))
        email = queryParams.get("email")
    }
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      setStateType(PROMPT_STATE);
    }
    else{
      finishVerification(email);
    }
  }else{
    setErrMessage("This doesn't seems to be right verification link. How did you get there? Please contact us if you need any help.");
    setStateType(ERR_STATE);
    
  }

},[]);

 function finishVerification(email){
  if (!email){
    setErrMessage("Please enter your email into the field above");
    return;
   }
   if (!validateEmail(email)){
    setErrMessage("This doesn't seems to be a valid email format");
    return;
   }
  signInWithEmailLink(auth, email, window.location.href)
  .then((result) => {
    setStateType(OK_STATE);
    setDoc(doc(db, "emails", email), {
      verified:true
    });
    // Clear email from storage.
    window.localStorage.removeItem('emailForSignIn');
    // You can access the new user via result.user
    // Additional user info profile not available via:
    // result.additionalUserInfo.profile == null
    // You can check if the user is new or existing:
    // result.additionalUserInfo.isNewUser
  })
  .catch((error) => {
    // Some error occurred, you can inspect the code: error.code
    // Common errors could be invalid email and invalid or expired OTPs.
    console.log(error);
    if (stateType!==PROMPT_STATE)
      setStateType(ERR_STATE); 
    if (error.toString().includes("auth/invalid-action-code")){
      setErrMessage("Sorry, but verification failed😔. May be you clicked on wrong verification link?")
      
    }
    else if (error.toString().includes("auth/invalid-email")){
      setStateType(PROMPT_STATE);
      setErrMessage("Sorry, but email you provided doesn't match the verification request")
    }
    else{
      setErrMessage(`Sorry, but verification failed😔. Are you sure that you entered the right email? (${email}). Please use any type of contact method from below and let us help you solve this.`)
    }
    setDoc(doc(db, "emails", email), {
      error:error.toString()
    });
    
    //setErrMessage(error.message);
   
  });
}

let body;
if (stateType==OK_STATE){
  body= (
      <ContentBlock
        type="left"
        title={"Welcome on board🎉"}
        content={" You are now officialy on our waiting list. We will contact you shortly with further information."}
        picture="waving.svg"
        id="finish_signup"
        button={[{
          "title": "Go back",
          "color":"white",
          "action":()=>window.location.href="/"
          
        }]}
     />
    )
 
}
else if (stateType==PROMPT_STATE){


  body = (
    <ContentBlock
      type="left"
      customContent={(
        <Box>
    <Text size="20pt">Almost there</Text>
    <Text>You've probably opened the link in a different browser. Please enter the email you used for registration to finish the verification</Text>
    <Input
              type="text"
              label="Email you used for registration"
              name="email"
              placeholder="please enter your email"
              value={emailFieldValue}
              onChange={(event)=>setEmailFieldValue(event.target.value)}
            />
            <Box gap="small">
            {errMessge&&( <Text color="rgb(250,50,50)">{errMessge}</Text>)}
            <Button  onClick={()=>finishVerification(emailFieldValue)}>Finish verification</Button>

            </Box>
  </Box>
      )}
      picture="waving.svg"
    />
  )
}
else if (stateType==ERR_STATE){
  body = (
    <ContentBlock
      type="left"
      customContent={(
        <Box>
          <Text size="20pt">Ouch... something is not right.</Text>
          <Text>{errMessge}</Text>

        </Box>
      )}
      picture="developer.svg"
    />
  )

}
  
return (    <Container>{body}</Container>);

}
export default FinishSignUp;
