import { Row, Col } from "antd";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../../common/SvgIcon";
import { ContentBlockProps } from "../types";
import {Carousel,Box, Text} from "grommet";
import { Fade } from "react-awesome-reveal";
import { Button } from "../../../common/Button";
import preprocessText from "../../../common/utils/preprocessText";
import {MdOutlineNavigateNext} from "react-icons/md"

import { logEvent } from "firebase/analytics";
import { analytics } from "../../../auth/firebase";
import {
  LeftContentSection,
  Content,
  ContentWrapper,
  ServiceWrapper,
  MinTitle,
  MinPara,
} from "./styles";


const LeftContentBlock = ({
  picture,
  title,
  content,
  section,
  t,
  id,
  button,
  customContent,
  showCarouselLinks,
  carouselData,
  justBody,
  pictureSize,
  definingPictureAxis
}: ContentBlockProps) => {

  let imgPart:any;
  if (typeof(picture) ==="string"){
    if (picture.endsWith("svg")){
      imgPart=(<SvgIcon src={picture} height={pictureSize||"80%"} width="auto" />);
    }
    else{
      imgPart=( <img className="contentPicture" src={picture} style={{width:definingPictureAxis=="X"?pictureSize||"100%":"auto",height:definingPictureAxis=="Y"?pictureSize||"100%":"auto"}}/>);
    }
  }
  else{
    imgPart=picture;
  }
  const [cardId, _setCardId] = useState(0);
  const [previousCardId, setPreviousCardId] = useState(0);

  const setCardId =(val:number)=>{
    setPreviousCardId(cardId)
    _setCardId(val);
    logEvent(analytics,"next_card",{"title":title, "cardId":val});
  }

  const [isInView,setIsInView] =useState(false);
  const scrollTo = (id: string) => {
    
    const element = document.getElementById(id) as HTMLDivElement;
    
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  let contentBody;
  if (customContent)
    contentBody=customContent;
  else{
    contentBody=(
      <>
      <h6>{preprocessText(title,t)}</h6>
      <Content>{preprocessText(content,t)}</Content>
      {(carouselData) && !showCarouselLinks && <a onClick={()=>setCardId(cardId+1)}><Box align="end" direction="row">Read more<MdOutlineNavigateNext size="20pt"/></Box> </a>}

      {(carouselData) && showCarouselLinks  && 
      (
        <Box>
        <Text>Find out more</Text>
        {Object.getOwnPropertyNames(carouselData).map(carouselCardName=>(
                  <a key={carouselCardName} onClick={()=>setCardId(Object.getOwnPropertyNames(carouselData).indexOf(carouselCardName)+1)}><Box align="end" direction="row"><MdOutlineNavigateNext size="20pt"/>{carouselCardName}</Box> </a>
               ))
        } 
        </Box>
      )}
      <ServiceWrapper>
        <Row justify="space-between">
        </Row>
        <Box direction="row">
        {typeof button === "object" &&
          button.map((item: any, id: number) => {
            return (
              <Button
                key={id}
                color={item.color}
                fixedWidth={true}
                onClick={() =>{
                  (item.action ? item.action() : scrollTo(item.target));
                } }
              >
                {t(item.title)}
              </Button>
            );
          })}
          </Box>
      </ServiceWrapper>
      </>
    )
  }

  let body =(
    <Row justify="space-between" align="middle" >
    <Col lg={13}  sm={20} xs={20}  >
      <Box fill align="center">
        {imgPart}

      </Box>
    </Col>
    <Col lg={13}  sm={20} xs={20}>
      <ContentWrapper>
        {contentBody}
      </ContentWrapper>
    </Col>
  </Row>
  );

  if (justBody) return (body);
  if (carouselData){
    body =  (<Carousel className="carousel" alignSelf="center" activeChild={cardId} onChild={(i)=> {
          if (i===cardId-1) {
            setCardId(previousCardId) 
          } else if (i!==cardId){

            setCardId(i)
          }
        }
      } >
      <div className="carouselCard" >{body}</div>
      {Object.getOwnPropertyNames(carouselData).map((carouselId)=><div key={carouselId} className="carouselCard" >{carouselData[carouselId]}</div>)}
      </Carousel>
    );
  }



  return (
    <Box  id={id} pad="20px 0px" >
      
    <LeftContentSection   className={isInView?"revealed":""}>
       {body}
    </LeftContentSection>
      
      </Box>
  );
};

export default withTranslation()(LeftContentBlock);
