import { lazy, useEffect, useState } from "react";
import {Box, Button, FormField, Text, TextInput} from "grommet"


import { useAuth } from "../../auth/AuthProvider";
import { FaBackward } from "react-icons/fa";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../auth/firebase";

const CountDown = ({start,onFinish, redirectNowButtonLabel}:any)=>{
    const [couter,setCounter] = useState(start);

    const countDown = (i:number)=>{
        setTimeout(()=>{
            if (i>0){
                setCounter(i-1)
                countDown(i-1)
            }
            else{
                if (document.location.href.endsWith("/demoLogin")){
                    onFinish()
                }
            }
        },1000)
    }
    useEffect(()=>{
        countDown(start)
    },[])

    return (
        <Box>
            <Text>You will be redirected in {couter} seconds... </Text>
            <Button label={redirectNowButtonLabel} onClick={()=>onFinish()}/>
        </Box>
    )
}
const cyrb53 = function(str:string, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
    h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1>>>0);
};
const DemoLogin= ()=>{


    useEffect(()=>{

        logEvent(analytics,"demoLogin")
      },[]);
      

    const {currentUser, signUpDemoUser} =useAuth();

    const [nickname,setNickname] = useState("");
    const [email,setEmail] = useState("");
    const [err,setErr] = useState("");


    const LoginDemo = ()=>{
        let hash=cyrb53(currentUser.email,(new Date()).getDate())
        document.location.href="https://app.labelator.io/projects?demo="+currentUser.email+"&checksum="+hash
    }

    const validateEmail = (email: String) => {
        
        logEvent(analytics,"demoLogin-validateEmail")
         let res= /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test( String(email));
          if (!res){
            setErr("This doesn't seems to be a valid email")
          }
          else{
            setErr("")
          }
        return res;
      };
    
    return (
    <Box pad="20px">
        <Box direction="row" gap="small" align="center" focusIndicator={false} onClick={()=>document.location.href=process.env.REACT_APP_PUBLIC_URL as string}><FaBackward/>Go back</Box>
    <Box  align="center" justify="center" height="90vh">
        <Box gap="small">
            Hello {currentUser?(currentUser.displayName || currentUser.email):(<Text>
                We don't know each other yet, <br/>please leave us your email to login into demo
            </Text>)}
             {!currentUser&& (
                <Box flex="grow">
                    
                    <FormField>
                        <TextInput  size="small" placeholder="Your name or nickname (optional)" onChange={e=>setNickname( e.target.value as string)}/>
                        </FormField>
                        <FormField>
                        <TextInput size="small" placeholder="Your email" onChange={e=>setEmail( e.target.value as string)}/>
                        </FormField>
                        {err && <Text color="red">{err}</Text>}
                    <Button margin="10px" label="Login as Demo user" onClick={()=>{

                        if (validateEmail(email)){
                            signUpDemoUser(email, nickname)
                        }
                    }}/>
                </Box>
             )}
             {
                 currentUser&& email /*email is here to be sure that redirect should be requested*/&& (
                    <CountDown start={5} onFinish={()=>LoginDemo()} redirectNowButtonLabel="Let's go" />
                 )
             }
             {
                  currentUser&& !email  &&(
                      <Box>
                    <Button margin="10px" label="Login as Demo user" onClick={()=>{
                       LoginDemo()
                    }}/>
                    <Button margin="10px" label="Go back" onClick={()=>{
                        logEvent(analytics,"demoLoginBack")
                        document.location.href= process.env.REACT_APP_PUBLIC_URL as string
                    }}/>
                    </Box>
                  )
             }
        </Box>
        
    </Box>
    </Box>
);
    }

export default DemoLogin;