import { Box, Text } from "grommet";
import { Link, useLocation } from "react-router-dom";
import { Route, Routes, useMatch } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "../../common/Container";
import preprocessText from "../../common/utils/preprocessText";
import ContentBlock from "../../components/ContentBlock";
import ProgressiveImage from "../../components/ProgresiveImage";
import { Integration } from "./Integration";
import { Labeling } from "./Labeling";
import { Serving } from "./Serving";
import { Training } from "./Training";
import "./styles.css"
import { SupportedTasks } from "./SupportedTasks";
import ScrollToTop from "../../common/ScrollToTop";
import { useEffect } from "react";
import { HumanInTheLoop } from "./HumanInTheLoop";

const LinkButton=({to, label, icon}:any)=>{
    const location = useLocation();
    let isActive=location.pathname?.endsWith(to);
    return <Link to={to}>
            <Box pad="5px 10px" margin="5px" round direction="row" align="center" justify="center" gap="5px" background={isActive?"orange":undefined}>
                {icon}
                 <Text weight={800} color={isActive?"white":undefined}>{label}</Text>
            </Box>
        </Link>
}

const Product = () => {

    const navigate = useNavigate();
    const scrollTo = (id: string) => {

        const element = document.getElementById(id) as HTMLDivElement;
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
            });
        }
        else {
            setTimeout(() => {
                const element = document.getElementById(id) as HTMLDivElement;
                element && element.scrollIntoView({
                    behavior: "smooth",
                });
            }, 500)
        }
    };
    const [searchParams, setSearchParams] = useSearchParams();
    const {pathname} = useLocation();
    useEffect(()=>scrollTo("top"),[pathname])
    return (
        <Container>
            
            <Box className="productHeader" direction="row" wrap gap="0px" justify="center" pad="20px" >
            <LinkButton to="labeling" label="Labeling"/>
            <LinkButton to="training" label="Training" />
            <LinkButton to="human-in-the-loop" label="Human in the loop" />
            <LinkButton to="serving" label="Model hosting" />
            <LinkButton to="integration" label="Integration" />
            <LinkButton to="tasks" label="Supported NLP tasks" />
            </Box>
<Box pad="2vw">
    <div id="#top"></div>
<Routes>
    <Route path="labeling" element={<Labeling/>}/>
    <Route path="training" element={<Training/>}/>
    <Route path="human-in-the-loop" element={<HumanInTheLoop/>}/>
    <Route path="serving" element={<Serving/>}/>
    <Route path="integration" element={<Integration/>}/>
    <Route path="tasks" element={<SupportedTasks/>}/>
</Routes>
</Box>
           
        </Container>
    )
}

export {Product}