import { lazy, useEffect, useState } from "react";
import {Box, Button, Header, Text} from "grommet"
import "./styles.css"

import {FaCog, FaReply, FaRocket} from "react-icons/fa";
import SignUp from "../../components/SignUp";


import {BsChevronDoubleDown, BsChevronDown, BsFillCloudUploadFill,BsTagsFill} from "react-icons/bs"
import {GrCpu, GrDirections, GrLaunch} from "react-icons/gr"
import ProgressiveImage from "../../components/ProgresiveImage";
import { Col, Row } from "antd";
import { MdAutoFixHigh } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import { VerticalTab } from "../../components/VerticalTab";
import { ContentBox } from "../../components/ContentBox/ContentBox";

import preprocessText from "../../common/utils/preprocessText";

import servingPng from "../../assets/labelatorio_serving_clip.png"
import simialarPng from "../../assets/labelatorio_similar_clip.png"
import trainingPng from "../../assets/labelatorio_training_clip.png"


import colectDataPng from "../../assets/1_colect_data.png"
import animationGif from "../../assets/explanatory-animation-gif.gif"
import left2right3 from "../../assets/svgs/3_left2right.svg"
import { Button as CommonButton } from "../../common/Button";
import YoutubeEmbed from "../../components/YoutubeEmbed/YoutubeEmbed";

const Contact = lazy(() => import("../../components/ContactForm"));
const MiddleBlock = lazy(() => import("../../components/MiddleBlock"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../../components/ContentBlock"));
const PriceBox = lazy(() => import("../../components/PriceBox"));



const Home = () => {
const [videoOpenState, setVideoOpenState] = useState(false);
const navigate = useNavigate();
const scrollTo = (id: string) => {
    
  const element = document.getElementById(id) as HTMLDivElement;
  if (element){
    element.scrollIntoView({
      behavior: "smooth",
    });
  }
  else{
    setTimeout(()=>{
      const element = document.getElementById(id) as HTMLDivElement;
      element && element.scrollIntoView({
        behavior: "smooth",
      });
    },500)
  }
};
const [searchParams, setSearchParams] = useSearchParams();  
useEffect(()=>{
  if (searchParams && searchParams.has("email")){
    scrollTo("signUp")
  }
},[searchParams])

  return (

    <Container>
      

      <Box  style={{minHeight:"80vh"}}>  
        <Box pad="10px 0px">
          <ContentBox type="right" img={
        <ProgressiveImage src={animationGif}/>
        } 
        imgPadding="0px 10% 0px 10%"
        >
          
            <Box gap="15px">
              <Text className="mainHeaderText" size="200%">Automate your processes with text analysis</Text>

              
              <Text size="110%">No-code studio where <b>You train an AI to assign labels</b> to your data with <span className="underline">human supervision</span>. </Text><></> <Text size="110%"><b>Annotate your data</b>, build and deploy <b>custom ML model</b> in a <b>few minutes</b> </Text>
              <Box direction="row" gap="15px">

              <Box  style={{ width: "180px" }}
            onClick={() => scrollTo("signUp")}>
              <CommonButton>Request access</CommonButton>
            </Box>
                <a href="/product/labeling" >
                <Button onClick={(e)=>{ e.preventDefault(); navigate("/demo/login")}} label="Live demo" secondary/>
              </a>
              </Box>
            </Box>
          </ContentBox>
        </Box>
      </Box>
        <YoutubeEmbed embedId="rGtEABCJyaY"/>

        <Box width="100%" margin="50px 0px"  align="center" focusIndicator={false} onClick={()=>scrollTo("how-it-works")}>
          How it works
          <BsChevronDown/>
        </Box>


      <Box id="how-it-works" margin="260px 0px 0px" pad="200px 0px 0px">
      <ContentBox type="left" img={
          <>
            <Box direction="row" justify="start" margin="-250px -0px -10px 10px" >
              <Box style={{ transform: "rotate(5deg)" }} margin="-1px -15px -0px 20px" justify="center" align="center" direction="row" >
                <Text size="110%" weight={900} >1.</Text> <Text weight={600} size="100%" style={{whiteSpace:"nowrap"}}> Collect your data</Text>
              </Box>
              <img src={colectDataPng} style={{ width: "60%" }} />
            </Box>

            <Box pad="5% 20% 0% 10%">
              <ProgressiveImage src={simialarPng} />

            </Box>
            <Box direction="row" style={{ transform: "rotate(-5deg)" }} margin="10px 10% 100px" align="start" alignSelf="end" justify="center" gap="8px">
              <Text size="130%" weight={900} >2.</Text>
              <Text weight={600} size="90%" style={{ whiteSpace: "nowrap" }} >Prepare your training data with <br /> <Text size="160%" className="underline bold">10x faster labeling!</Text> </Text>
            </Box>
          </>
        } >
            <Box gap="8px" margin="50px 0px" align="start">

              <Text className="mainHeaderText" size="20pt">Bulk labeling with instant similarity search</Text>
              <Text size="large">{preprocessText("*Don't label records one-by-one*. // Thanks to automatic similarity search, as you label one record, you immediately see all the *similar records* and you can *label them all at once*! // This way we were able to label *1000 records in 10 minutes* ")}<span style={{whiteSpace:"nowrap"}}>(by actually reviewing all of them) !</span> </Text>
              
              <a href="/product/labeling" >
                <Button onClick={(e)=>{ e.preventDefault(); navigate("/product/labeling")}} label="Learn more..." secondary/>
              </a>
              
              
            
            </Box>
          </ContentBox>
        </Box>


        <Box pad="80px 0px">
          <ContentBox type="right" img={
            <>
             <Box direction="row" style={{ transform: "rotate(5deg)" }} margin="10px -15% 10px" align="start" alignSelf="start" justify="center" gap="8px">
              <Text size="1.3rem" weight={900} >3.</Text>
              <Text weight={600} size="120%" style={{ whiteSpace: "nowrap" }} >Train with one click <Text weight={600} size="70%" style={{ whiteSpace: "nowrap" }} > <br />  and evaluate the results</Text></Text>
            </Box>
            <ProgressiveImage src={trainingPng}/> 
            </>
          } imgPadding="5% 5% 0% 15%">
            <Box gap="8px">

              <Text className="mainHeaderText" size="20pt">Train your model and analyze the predictions</Text>
              <Text size="large">{preprocessText("Train a new model with a single click and *analyze the mistakes*.// Find or generate more similar examples to train on, so the model would learn how to handle them.")}</Text>
              <a href="/product/training" >
                <Button onClick={(e)=>{ e.preventDefault(); navigate("/product/training")}} label="Learn more..." secondary/>
              </a>
            </Box>
          </ContentBox>
        </Box>
      

      <Box >  
        <Box pad="0px">
          <ContentBox type="left" blobBackground={false} img={
            <div style={{left:"-20px", marginLeft:"-20px"}}>
              <Box margin="0px 100px" style={{ transform: "rotate(5deg)" }}>
              <Text weight={600} size="1.5rem" >4. Deploy 🚀</Text>
              </Box>
              
      
              
              <ProgressiveImage src={servingPng}/> 
              <Box margin="-35px 20px 0px" justify="end" style={{ transform: "rotate(5deg)" }} direction="row" gap="10px">
              <Text weight={600} size="120%" >5.</Text>
                <Box>
              <Text weight={600} size="110%">Review and correct</Text>
              <Text weight={600} size="110%" >uncertain predictions 🔍</Text>
                </Box>
              </Box>
            </div>
        }>
            <Box gap="8px" pad="50px 0px">

              <Text className="mainHeaderText" size="30pt">You decide what to automate</Text>
              <Text size="large">{preprocessText("Even in sensitive use cases, where no mistake can slip through, use AI to *automate the most common cases* and route only the edge cases to the operator.// You decide what can be automated by defining *example records*. These and similar records would be routed through an AI.// The rest will be sent to an operator until the AI learns how to handle even these complicated scenarios. ")}</Text>
              <a href="/product/human-in-the-loop" >
                <Button onClick={(e)=>{ e.preventDefault(); navigate("/product/human-in-the-loop")}} label="Learn more..." secondary/>
              </a>
            </Box>
          </ContentBox>
        </Box>
      </Box>

    <Box align="center" margin="30vh 0px">
      <Box width="70%" >

      <SignUp
        title={"Request access"}
        content={"For now, we are onboarding our customers one by one to ensure the highest quality of service.// If you are interested in using Labelator.io, sign up and we will contact you shortly with further details."}
        id="signUp"
        />
      </Box>
      </Box>

    </Container>
  );
};

export default Home;
