import styled from "styled-components";

export const StyledButton = styled("button")<any>`
  background: ${(p) => p.color || "linear-gradient(213deg, rgb(243 136 40 / 84%) 0%, rgb(179 54 179 / 93%) 120%)"};
  color: ${(p) => (p.color ? "#b336b3" : "#fff")};
  font-size: 1rem;
  font-weight: 900;
  width: 100%;
  border: 0px ;
  border-radius: 25px;
  padding: 5px 0;
  cursor: pointer;
  
  max-width: 180px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 6px 10px rgb(23 31 114 / 20%);

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    
    background-color: rgb(243 136 40 / 84%);
    box-shadow: 0 16px 30px rgb(23 31 114 / 20%);
    maring-top:-2px;
  }
`;
