import React, { useContext, createContext,useState, useEffect } from "react";
import {auth,db} from "./firebase";
import {  
    signInWithPopup, 
    sendEmailVerification,
    updateProfile,
    sendSignInLinkToEmail,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword
    
} from "firebase/auth";

import { doc, setDoc, getDoc } from "firebase/firestore"; 

const AuthContext = React.createContext();



export function useAuth(){
    return useContext(AuthContext);
}

export function AuthProvider({children}){
    const [currentUser, setCurrentUser] = useState();
    


    useEffect(()=>{
        return auth.onAuthStateChanged(user=>setCurrentUser(user));
    },[]);

    function logout(){
        return auth.signOut();
    }

    function signUp(email){
        
        return sendSignInLinkToEmail(auth, email, {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: process.env.REACT_APP_PUBLIC_URL +'/finishSignUp?email='+encodeURI(email),
            // This must be true.
            handleCodeInApp: true,
          })
        .then((result) => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', email);
            return result;
        })
        .catch(err=>({error:err}))
       
    }
    const cyrb53 = function(str, seed = 0) {
        let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
        h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
        return 4294967296 * (2097151 & h2) + (h1>>>0);
    };

    function signUpDemoUser(email, displayName){
        
        const handleSuccess=(result) => {
                if (displayName){
                    const user = auth.currentUser;
                    return updateProfile(user,{
                        displayName: displayName
                    })              
                }
                
        }

        return signInWithEmailAndPassword(auth, email, cyrb53(email)) //first try to login, just in case this user has been here already
        .then(handleSuccess)
        .catch((err)=>{
            console.log(err);
            return createUserWithEmailAndPassword(auth, email, cyrb53(email))
                .then(handleSuccess)
        })
        
       
    }

    function sendVerificationEmail(){
        
            return sendEmailVerification(currentUser, {
                // URL you want to redirect back to. The domain (www.example.com) for this
                // URL must be in the authorized domains list in the Firebase Console.
                url: process.env.REACT_APP_PUBLIC_URL +'/finishSignUp?email='+encodeURI( currentUser.email),
                // This must be true.
                handleCodeInApp: true,
              }).then(()=>{
                setDoc(doc(db, "emails", currentUser.email), {
                    verificationSent:true
                });
                return true;

            })
        
    }

    function signUpWith(provider){
        signInWithPopup(auth, provider)
        .then((result) => {
            setCurrentUser(result.user);
            // ...
        }).catch((error) => {
            console.log(error.message)
            //setError(error.message)
        });
    }

    const value = {
        currentUser,
        signUp,
        signUpWith,
        signUpDemoUser,
        sendVerificationEmail,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
        {children}
        </AuthContext.Provider>
        
        );
}