import { Box, Text } from "grommet";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "../../common/Container";
import ContentBlock from "../../components/ContentBlock";
import ProgressiveImage from "../../components/ProgresiveImage";

const Labeling = () => {

    const navigate = useNavigate();
    const scrollTo = (id: string) => {

        const element = document.getElementById(id) as HTMLDivElement;
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
            });
        }
        else {
            setTimeout(() => {
                const element = document.getElementById(id) as HTMLDivElement;
                element && element.scrollIntoView({
                    behavior: "smooth",
                });
            }, 500)
        }
    };
    const [searchParams, setSearchParams] = useSearchParams();

    return (
            
        <Box  gap="10px">
                <Text weight={900}  size="150%" >The <span style={{fontSize:"100%"}} className="underline">best labeling</span> experience for text classification</Text>

                

                <ContentBlock
                    id="labeling"
                    type="right"
                    title="Similarity-based bulk labeling"
                    content={"Don't label the data one by one !... it's super dull.// *Label all similar records at once*, and save up to *90%* of your time. //Autopredict the labels to improve your productivity even more. "}

                    picture={
                        <Box margin="0px" fill align="center" justify="center">
                            <ProgressiveImage placeholder="/img/Labelatorio-keyboard_placeholder.png" src="/img/Labelatorio-keyboard.gif" />

                        </Box>
                    }



                />
                <ContentBlock id="labelPrediction" type="right" title="Most likely label prediction"
                    content="Especially when you have *many classes*, it can be overwhelming to find the right one among all of the options... Labelator.io automatically limits the number of classes shown to the *most likely*"
                    picture="/img/labelatorio_likely_labels.png" pictureSize="75%" justBody />

                <ContentBlock id="keyWords" type="right" title="Label keywords"
                    content="Define your own *keywords* for each label and color. They (and similar) words will be automatically *highlighted* with the appropriate color to help you spot the *decisive parts of the text* to help you decide better"

                    picture="/img/labelatorio-keywords.png" pictureSize="100%" justBody />
                <ContentBlock id="mobile_labeling" type="right" title="Mobile labeling"
                    content={(
                        <Box align="start" margin="0px 0px 50px 0px">
                            Optimized for labeling on mobile. This allows you to label / process your data anywhere...
                        </Box>
                    )}
                    picture="/img/labelatorio_mobile.png" pictureSize="35%" definingPictureAxis="Y" justBody />
     
        </Box>
    )
}

export { Labeling }