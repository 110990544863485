import styled from "styled-components";

export const StyledContainer = styled("div")<any>`
  position: relative;
  width: 100%;

  margin-right: auto;
  margin-left: auto;
  padding: 0 0px;
  border-top: ${(p) => (p.border ? "1px solid #CDD1D4" : "")};

  @media only screen and (max-width: 1024px) {
 

  }

  @media only screen and (max-width: 768px) {


  }

  @media only screen and (max-width: 414px) {


  }
`;
