
import { VscCommentDiscussion } from 'react-icons/vsc';
import { Box, Text } from "grommet";
import PriceBox from '../PriceBox';
import { useEffect, useRef, useState } from 'react';
import SignUp from '../SignUp';

const PricingSection = () => {

    const [selectd,setSelected]=useState("Growth")
    const scrollTo = (id) => {
    
        const element = document.getElementById(id);
        if (element){
          element.scrollIntoView({
            behavior: "smooth",
          });
        }
        else{
          setTimeout(()=>{
            const element = document.getElementById(id)
            element && element.scrollIntoView({
              behavior: "smooth",
            });
          },500)
        }
      };

    const topRef = useRef()
    useEffect(()=>topRef.current&& scrollTo("top"),[topRef])
return (
    <Box align='center'>
    <Box id="top" ref={topRef} direction='row' align='center' justify='center' pad="50px 20px" wrap>
        
        {/* <PriceBox 
        name='Free'

        price='comming soon'
        enabled={false}
        isSelected={selectd=="Free"}
        onContinue={()=>scrollTo("signup")}
        onSelected={val=>setSelected(val?"Free":undefined)}
        features={[
            "data are publicly availible 👀",
            "bulk labeling",
            "single project",
            "up to 10k records",
            "-no model training",
            "-no API access availible"

        ]}/> */}

        <PriceBox 
        name='Startup'
        price='from 69$ / month'
        isSelected={selectd=="Startup"}
        onContinue={()=>scrollTo("signup")}
        onSelected={val=>setSelected(val?"Startup":undefined)}
        features={[
           // "data are private 🔒",
            "single project",
            "up to 10k records",
            "1 h / mo model training included",
            "max 1 user",
            "shared model hosting",
            "basic Slack support",
            "API access and webhooks with 1000 calls/day limit"
            
            
            
        ]}/>
        
        
         <PriceBox 
        name='Growth'
        primary
        isSelected={selectd=="Growth"}
        onSelected={val=>setSelected(val?"Growth":undefined)}
        onContinue={()=>scrollTo("signup")}
        price='from 699$ / month'
        features={[
         //   "data are private 🔒",
            "up to 10 projects",
            "up to 500k records",
            "5 users included",
            "20 h / mo of model training included",
            "shared model hosting included",
            "managed hosting options",
            "unlimited API access and webhooks",
            "Slack and Email support",
        ]}

       />

<PriceBox 
        name='Enterprise'
        price='contact sales'
        isSelected={selectd=="Enterprise"}
        onContinue={()=>scrollTo("signup")}
        onSelected={val=>setSelected(val?"Enterprise":undefined)}
        features={[
         //   "data are private 🔒",
            "unlimited projects",
            "unlimited data storage",
            "unlimited users",
            "dedicated separate environment",
            "priority Slack and Email support",
            "SLA for uptime and support",
            
        ]}
        />
    
    </Box>
    
    <Box width="80%" id="signup">

    <SignUp title={selectd?`Continue`:"Not sure what to pick?"} content={selectd?`Selected price range: *${selectd}* // Don't worry, this is just preliminary. /We will be contacting you to discuss the right option for you.`:"Sign up risk-free and we will contact you to pick the right solution for you"}/>
    </Box>
    </Box>

)};

export { PricingSection };