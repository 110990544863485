import styled from "styled-components";

export const PictureWrapper = styled("section")`
  position: relative;
  padding: 1rem 0 0.5rem;

  
  margin:50px 5px 100px 5px;


  &&::before {
    /* Highlight color */
    background-color:  rgb(255, 176, 192) ;
    box-shadow: 0 0 25px rgba(250,0,0,0.1);
    content: "";
    position: absolute;
    width: calc(100% );
    height: calc(100% );
    left: 5px;
    bottom: 2px;
    z-index: -1;
    transform: rotate(1.0deg);
    border-radius:20px;
    background:rgba(245,240,240,0.1);
    backdrop-filter: blur(20px);
  }

  box-shadow: 0 0 25px rgba(250,0,0,0.1);
  border-radius:20px;
  background:rgba(255,255,255,0.1);
  backdrop-filter: blur(20px);

  @media only screen and (max-width: 1024px) {
    padding:25px;
    margin:150px 5px 100px 5px;
    
  }

  @media only screen and (max-width: 768px) {
    margin-top:50px;
    padding:10px;
    
    backdrop-filter: none;
  }
`;

export const RightBlockContainer = styled("section")`
  position: relative;
  padding: 1rem 0 0.5rem;
  margin:5px;
  margin:50px 5px 50px 5px;




  @media only screen and (max-width: 1024px) {
    padding:25px;
    margin:50px 5px 50px 5px;
    
  }

  @media only screen and (max-width: 768px) {
    margin-top:50px;
    padding:10px;
    
    backdrop-filter: none;
  }
`;

export const Content = styled("p")`
  margin: 1.5rem 0 2rem 0;
  


`;


export const ContentWrapper = styled("div")`
  position: relative;
  max-width: 540px;
  

  @media only screen and (max-width: 575px) {
    padding-bottom: 2.5rem;
  }
`;

export const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  max-width: 100%;

  @media screen and (min-width: 1024px) {
    max-width: 80%;
  }

  button:last-child {
    margin-left: 20px;
  }
`;
