import { Box, Text } from "grommet";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "../../common/Container";
import ContentBlock from "../../components/ContentBlock";
import ProgressiveImage from "../../components/ProgresiveImage";
import nodeConfigPng from "../../assets/product/node-config.png"
import backlogPng from "../../assets/product/backlog.png"
import Block from "../../components/Block";
import { ContentBox } from "../../components/ContentBox/ContentBox";
const HumanInTheLoop = () => {
    
    const navigate = useNavigate();
    const scrollTo = (id: string) => {

        const element = document.getElementById(id) as HTMLDivElement;
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
            });
        }
        else {
            setTimeout(() => {
                const element = document.getElementById(id) as HTMLDivElement;
                element && element.scrollIntoView({
                    behavior: "smooth",
                });
            }, 500)
        }
    };
    const [searchParams, setSearchParams] = useSearchParams();

    return (

        <Container>


<ContentBox type="right" img={<img style={{margin:"-120px 0px 0px", height:"auto", width:"100%"}} src={nodeConfigPng}/>}  imgPadding="0px 5% 0px 25% ">
<Box pad="0px 0px 50px 0px ">
<h6>Smart routing</h6>
<p>Be in control of your  <span className="underline">human-in-the-loop </span> pipeline </p>
<p>When running a model in production you can define rules on what data should be automatically predicted by your model and what should be handled or reviewed by the operator.</p>
<p> By setting routing rules you can define when are the predictions: 
    <ul> 
        <li>fully automated</li>
        <li>predicted but send for additional review</li>
        <li>hold for review</li>
    </ul> 
</p>
<p>You decide the rules when and what you automate and to which degree.</p>
<p>You can define the rules by similarity to:</p>
    <ul> 
        <li>predefined exact set of <span className="underline">example records</span>    </li>
        <li>all the  <span className="underline">correctly predicted records</span>    </li>
    
    </ul>
 
        </Box>
</ContentBox>
<ContentBlock
id="backlog"
type="right"
title="Operators backlog"
content={<Box><Text>Dedicated backlog of new records, that need to be reviewed or labeled. </Text><Text> Every change made here can be automatically synchronized into your system via <a href="/product/integration/#webhooks">Webhooks integration</a></Text></Box>}
picture= {backlogPng}

/>
</Container>
    )
}

export {HumanInTheLoop}