import styled from "styled-components";

// export const LeftContentSection = styled("section")`
//   position: relative;
//   padding: 80pt;
//   border-radius:30px;
//   backdrop-filter: blur(20px);
//   background: rgba(255,255,255, 0.8);
//   margin-right:-30%;

//   @media only screen and (max-width: 1024px) {
//     padding: 20px;
//   }
// `;


export const PriceBoxSection = styled("section")`
  position: relative;
  padding: 1rem 0 0.5rem;
  padding: 0px;
  height:90%;
  margin:10px 10px 10px 5px;
  display:inline-block;
  overflow:hidden;
  box-shadow: 0 0 25px rgba(220,100,150,0.5);
  border-radius:20px;
  
  backdrop-filter: blur(20px);
  
 

`;
export const Content = styled("p")`
  
`;

export const ContentWrapper = styled("div")`
  position: relative;
  max-width: 540px;
  margin: 1.5rem 1rem 2rem 1rem;
  

  @media only screen and (max-width: 575px) {
    padding-bottom: 2.5rem;
  }
`;

export const ServiceWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
`;

export const MinTitle = styled("h6")`
  font-size: 15px;
  line-height: 1rem;
  padding: 1.5rem 1rem;
  
  text-transform: uppercase;
  color: #000;
  font-family: "Motiva Sans Light", sans-serif;
`;

export const MinPara = styled("p")`
  font-size: 13px;
`;
