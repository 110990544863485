import { Box, Text } from "grommet";
import textClassificationPng from "../../assets/product/textClassification.png"
import multiLabaleTextClassificationPng from "../../assets/product/multiLabaleTextClassification.png"
import textSimilarityPng from "../../assets/product/textSimilarity.png"
import preprocessText from "../../common/utils/preprocessText";

import "./styles.css"

const SupportedTasks=()=>(
    <Box  pad="1vw" gap="20px">


      
      <Text size="xlarge">Supported NLP tasks</Text>
      <Box align="start" gap="small">

                    <Box border round="small" pad="10px" >
                        <Text weight={900}>Text classification</Text>
                        <Box direction="row-responsive"  pad="1vw" gap="20px">
                        <img className="nlpTask" src={textClassificationPng} />
                            <Box>
                                <Text>Assign 1 of many labels to your records</Text>
                                <Text>Typical use cases:

                                    <ul>
                                        <li>Sentiment analysis - automatically determine whether the author wrote about the topic in positive or negative tone</li>
                                        <li>Spam detection -  Filter our spam or another type of unwanted content</li>
                                        <li>Ticket classification - Determine whether the ticket is a bug or feature request </li>
                                        <li>Intent classification - Useful for chat-bots... determine what the user is trying to accomplish </li>
                                        <li><b>Your own classes</b> - Define your own types of records and automatically assign one of them to each record </li>
                                    </ul>
                                </Text>
                                
                            </Box>
                        </Box>
                    </Box>
                    <Box border round="small" pad="10px">
                        <Text weight={900}>Multi-label text classification</Text>
                        <Box  direction="row-responsive"  pad="20px" gap="20px">
                        <img className="nlpTask" src={multiLabaleTextClassificationPng} />
                        <Box >
                                <Text>Assign multiple of many labels to your records</Text>
                                <Text>Typical use cases:

                                    <ul>
                                        <li>Topic identification - Some documents are sometimes covering multiple topics, forcingly assigning just one might not be ideal. </li>
                                        <li>Automatic tagging - Assign one of many tags to your documents</li>
                                        <li><b>Your own labels</b> - Define your own types of records and automatically assign one of them to each record </li>
                                    </ul>
                                </Text>
                                
                            </Box>
                        </Box>
                    </Box>
                    <Box border round="small" pad="10px">
                        <Text weight={900}>Text similarity</Text>
                        <Box direction="row-responsive"  pad="20px" gap="20px">
                        <img className="nlpTask" src={textSimilarityPng} />
                            <Box >
                                <Text>Compare two or more records and quantify their similarity</Text>
                                <Text>Typical use casses:

                                    <ul>
                                        <li>Neural search - is a great emerging technology these days. Instead of searching by keywords, describe what are you looking for and the engine will find the documents  </li>
                                        <li>Similar problem lookup - Find similar problems in your issue tracker to </li>
                                        <li>Plagiarism identification - Find similar documents or their parts that are too similar to be plausibly written independently</li>
                                        
                                    </ul>
                                </Text>
                                
                            </Box>
                        </Box>
                    </Box>
                </Box>
        
    </Box>
)

export {SupportedTasks};