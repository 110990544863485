import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import { Box, Text } from "grommet";
import { MdOutlineAlternateEmail } from "react-icons/md"
import { FaFacebook, FaInstagram, FaSlack, FaTwitter, FaYoutube } from "react-icons/fa"
import { BiCopy } from "react-icons/bi"

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";
import { Logo } from "../Logo/logo";
import { useState } from "react";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  const socialIconSize = "35px";
  const [showMail, setShowMail] = useState(false);
  const [copiedState, setCopiedState] = useState(false);
  const mail = ["juraj" ,".", "bezdek", "@", "blip", ".solutions"];
  return (
    <FooterSection>
      <Box pad={{left:"20px"}}>
      <Box  gap="small" direction="row" justify="between" >

        <Box id="contact">
          <Language>{t("Contact")}</Language>
          <Box direction="column" gap="small">
            <Box>
              <Text >{t("Tell us everything")}</Text>
              <Para>
                {t(`Do you have any question? Feedback? Feel free to reach out.`)}
              </Para>
            </Box>
   


          {showMail && (<Box direction="row" >
            <Box direction="row" gap="3px" background={copiedState ? "dark-5" : ""} pad="2px 8px" round>
              <a href={["ma", "il", "to:", ...mail].join("")}>{mail.join("")} </a>
              <Box focusIndicator={false} onClick={() => {
                navigator.clipboard.writeText(mail.join("")).then(function () {
                  setCopiedState(true);
                  setTimeout(() => setCopiedState(false), 100);
                }, function (err) {
                  console.error('Async: Could not copy text: ', err);
                });
              }}>
                <BiCopy />
              </Box>
            </Box>
          </Box>)}
        </Box>
      </Box>
      <Box pad="0px 50px" margin="-10px 0px" align="end">
        <Logo pad="10px" iconBackground={false} textColor="black" size={"medium"} />
        <Text>developed by <a href="https://www.blip.solutions">Blip.solutions</a></Text>
        <Box margin="20px 0px" align="end">
          <a href="https://docs.labelator.io/docs/legal/terms-of-service"><Text color="gray">Terms of service</Text></a>
          <a href="https://docs.labelator.io/docs/legal/privacy-policy"><Text color="gray">Privacy policy</Text></a>
        </Box>
      </Box>
    </Box>
    <Box margin={{top:"10px"}} >
              <Box gap="medium" direction="row">
                <Box focusIndicator={false} onClick={() => setShowMail(!showMail)} ><MdOutlineAlternateEmail size={socialIconSize} /></Box>
                {/* <Box focusIndicator={false} onClick={() =>  window.open("https://www.youtube.com/channel/UCP4FcfafNtxTOfIUmt2SZFg", '_blank')!.focus() } ><FaYoutube size={socialIconSize} /></Box>
                <Box focusIndicator={false} onClick={() => window.open("https://www.facebook.com/commentie/", '_blank')!.focus() } ><FaFacebook size={socialIconSize} /></Box>*/}
                <Box focusIndicator={false} onClick={() => window.open("https://join.slack.com/t/labelatorio/shared_invite/zt-1dpn10chj-1EMqk3lhY1xkkygiJ4rGaQ", '_blank')!.focus() } ><FaSlack size={socialIconSize} /></Box> 
                <Box focusIndicator={false} onClick={() => window.open("https://twitter.com/labelator_io", '_blank')!.focus()  } ><FaTwitter size={socialIconSize} /></Box>
            </Box>
          </Box>
          </Box>
    </FooterSection >
  );

return (
  <>
    <FooterSection>
      <Container>

        <Row justify="space-between">
          <Col lg={10} md={10} sm={12} xs={12}>
            <Language>{t("Contact")}</Language>
            <Large to="/">{t("Tell us everything")}</Large>
            <Para>
              {t(`Do you have any question? Feedback? Feel free to reach out.`)}
            </Para>

          </Col>
          <Col lg={8} md={8} sm={12} xs={12}>
            <Box gap="small">
              <MdOutlineAlternateEmail size={socialIconSize} />
              <FaFacebook size={socialIconSize} />
              <FaInstagram size={socialIconSize} />
              <FaTwitter size={socialIconSize} />
            </Box>
          </Col>
        </Row>
        {/* <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{t("Address")}</Language>
              <Para>Rancho Santa Margarita</Para>
              <Para>2131 Elk Street</Para>
              <Para>California</Para>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              <Large left="true" to="/">
                {t("About")}
              </Large>
              <Large left="true" to="/">
                {t("Blog")}
              </Large>
              <Large left="true" to="/">
                {t("Press")}
              </Large>
              <Large left="true" to="/">
                {t("Careers & Culture")}
              </Large>
            </Col>
           <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="spain.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col> 
          </Row> */}
      </Container>
    </FooterSection>
    <Extra>
      <Container border={true}>
        <Row
          justify="space-between"
          align="middle"
          style={{ paddingTop: "3rem" }}
        >
          <NavLink to="/">
            <Logo pad="10px" iconBackground={false} textColor="black" size={"small"} />
          </NavLink>
          <FooterContainer>
            {/* <SocialLink
                href="https://github.com/Adrinlol/create-react-app-adrinlol"
                src="github.svg"
              />
              <SocialLink
                href="https://twitter.com/Adrinlolx"
                src="twitter.svg"
              />
              <SocialLink
                href="https://www.linkedin.com/in/lasha-kakabadze/"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://medium.com/@lashakakabadze/"
                src="medium.svg"
              /> */}

          </FooterContainer>
        </Row>
      </Container>
    </Extra>
  </>
);
};

export default withTranslation()(Footer);
