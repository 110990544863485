import { Box } from "grommet";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "../../common/Container";
import ContentBlock from "../../components/ContentBlock";
import ProgressiveImage from "../../components/ProgresiveImage";
import bigquery from "../../assets/product/bigquery.png"
import webhooks from "../../assets/product/webhooks.png"
import restApi from "../../assets/product/restApi.png"
const Integration = () => {
    
    const navigate = useNavigate();
    const scrollTo = (id: string) => {

        const element = document.getElementById(id) as HTMLDivElement;
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
            });
        }
        else {
            setTimeout(() => {
                const element = document.getElementById(id) as HTMLDivElement;
                element && element.scrollIntoView({
                    behavior: "smooth",
                });
            }, 500)
        }
    };
    const [searchParams, setSearchParams] = useSearchParams();

    return (

        <Container>
        <ContentBlock
        id="api"
        type="right"
        title="REST API integration"
        content={"Send your data to Labelator.io and define your *callback hooks* that would be triggered when data are labeled or the label is predicted. (requests are batched by 100 records)"
        +" ///Apply a *programmatic  approach* via API or our python client. //Anything you can do via GUI is available to you via API"}
        picture= {restApi}

      />

<ContentBlock
        id="python"
        type="right"
        title="Python SDK"
        content={"Our Python SDK is a wrapper over the REST API that hugely simplifies its usage.// TThis also allows a programmatic approach to labeling and simplifies integration with your own machine learning experiments. //Labelator.io can be used here 'just' as a labeling tool."}
        picture= "/img/api_code_example.png" 

      />
<ContentBlock
        id="bigquery"
        type="right"
        title="Google BigQuery"
        content={"You can integrate your project with data stored in Google BigQuery. // This way you can preprocess your data with all the power that this platform offers."}
        pictureSize="50%"
        picture={bigquery}

      />
<ContentBlock
        id="webhooks"
        type="right"
        title="Webhooks"
        content={"Every action that you do on the data should be synchronized to your application, where the data are primarily stored. //This can be easily achieved with webhooks. Define your listeners and Labelator.io would call them anytime the monitored action was performed.// This can be very useful when you are correcting wrong predictions and want to synchronize this change to the master application."}
        pictureSize="60%"
        picture={webhooks}

      />

        </Container>
    )
}

export {Integration}