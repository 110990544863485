import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import Home from "../pages/Home";
import FinishSignUp from "../pages/FinishSignUp";
import DemoLogin from "../pages/DemoLogin";
import { Labeling } from "../pages/Product/Labeling";
import { Product } from "../pages/Product/Product";
import { PricingSection } from "../components/PricingSection/pricing";


const CommonPageLayout = (content:any)=>(
<>
<Header/>
{content}
<Footer />
</>
)

const Router = () => {

  return (
    <Suspense fallback={null}>
      <Styles />
      <Routes>
      
        {/* {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              //exact={routeItem.exact}
              element={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })} */}
         <Route path="/" element={CommonPageLayout(<Home/>)}/>
         <Route path="/finishSignUp" element={CommonPageLayout(<FinishSignUp/>)}/>
         <Route path="/demoLogin" element={<DemoLogin/>}/>
         <Route path="/product" element={CommonPageLayout(<Product/>)}/>
         <Route path="/product/*" element={CommonPageLayout(<Product/>)}/>
         <Route path="/pricing" element={CommonPageLayout(<PricingSection/>)}/>
         <Route path="/*" element={CommonPageLayout(<Home/>)}/>
      </Routes>
      
    </Suspense>
  );
};

export default Router;
