import { Content } from "antd/lib/layout/layout";
import { Box } from "grommet";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "../../common/Container";
import ContentBlock from "../../components/ContentBlock";
import ProgressiveImage from "../../components/ProgresiveImage";
import evaluationPng from "../../assets/product/evaluation.png"
import continueTrainingPng from "../../assets/product/continue-training.png"
const Training = () => {
    
    const navigate = useNavigate();
    const scrollTo = (id: string) => {

        const element = document.getElementById(id) as HTMLDivElement;
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
            });
        }
        else {
            setTimeout(() => {
                const element = document.getElementById(id) as HTMLDivElement;
                element && element.scrollIntoView({
                    behavior: "smooth",
                });
            }, 500)
        }
    };


    return (

        <Container>
            <ContentBlock
        id="training"
        type="right"
        title="Model training"
        content={"Train your model anytime with just a *few clicks*. No need to *move your data around*!  //*Track and analyze* the results. //Use our prepared training tasks to finetune the current state-of-the-art models (PyTorch models with *🤗 Huggingface* trainers) //or use your own model."}
        picture= "/img/labelatorio_training.png" 

      />

        <ContentBlock
        id="continuous"
        type="right"
        title="Continuous training"
        content={"You can continue training your existing model on the new data, or even train it on completely different data from other projects. "}
        picture= {continueTrainingPng}
        pictureSize="60%"

      />
 <ContentBlock
        id="evaluation"
        type="right"
        title="Model evaluation"
        content={"Run the predictions on the current model and analyze on what data had the model wrong predictions or weak confidence. Then you can automatically look for similar not-yet labeled data in your project and label them, to enforce the expected behavior."}
        picture={evaluationPng}

      />
        </Container>
    )
}

export {Training}