import { Row, Col } from "antd";
import { useState, useEffect } from 'react';
import { Box, Form, TextInput, FormField, Heading, Grommet, grommet, Text, Button as GrommetButton, Spinner } from 'grommet';
import { BiMailSend } from "react-icons/bi";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineDownloadDone } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import {
  GoogleAuthProvider,
  FacebookAuthProvider
} from "firebase/auth";
import { withTranslation } from "react-i18next";
import { Slide, Zoom } from "react-awesome-reveal";
import { ContactProps, ValidationTypeProps } from "./types";
import { useForm } from "../../common/utils/useForm";
import validate from "../../common/utils/validationRules";
import { Button } from "../../common/Button";
import Block from "../Block";
import Input from "../../common/Input";
import TextArea from "../../common/TextArea";
import { ContactContainer, FormGroup, Span, ButtonContainer } from "./styles";
import { useAuth } from "../../auth/AuthProvider";
import preprocessText from "../../common/utils/preprocessText";

import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../../auth/firebase";
import { useSearchParams } from "react-router-dom";
import Header from "../Header";


const SignUp = ({ title, content, id, t }: ContactProps) => {
  const { values, errors, handleChange, handleSubmit } = useForm(
    validate
  ) as any;

  const { currentUser, signUp, signUpWith, logout, sendVerificationEmail } = useAuth();

  const ValidationType = ({ type }: ValidationTypeProps) => {
    const ErrorMessage = errors[type];
    return (
      // <Zoom direction="left">
        <Span erros={errors[type]}>{ErrorMessage}</Span>
      // </Zoom>
    );
  };



  const [error, _setError] = useState<string|undefined|null>(undefined);
  const setError=(err:String)=>{
    _setError(err.replace("Firebase:",""))
  }
  const [searchParams, setSearchParams] = useSearchParams();  
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState<boolean|"sending"|"sent">(false);

  useEffect(()=>{
    if (searchParams.has("email")){
      setEmail(searchParams.get("email")||"")
    }
  },[searchParams])

  useEffect(() => {
    try {
      if (currentUser) {
        getDoc(doc(db, "emails", currentUser.email)).then(doc => {

          setIsSent(doc.get("verificationSent")==true)

        });
      }
    } catch (ex) {
      console.log(ex)
    }
  }, [currentUser])



  const handleSignUpButton = () => {
    setError("");
    
    if (!email) {
      return setError("Please enter your email into the field above");
    }
    if (!validateEmail(email)) {
      return setError("This doesn't seems to be a valid email format");
    }



    signUp(email).then((res: any) => {

      if (res && res.error) {
        setError(res.error.message);
        setDoc(doc(db, "emails", email), {
          error: res.error.message
        });
      }
      else {
        setIsSent(true);
        setDoc(doc(db, "emails", email), {
          verificationSent: true
        });
      }
    });


   
  }

  function handleSendVerificationEmailButton() {
    setError("");
    setIsSent("sending")
    if (currentUser){
          sendVerificationEmail().then(() => {
            setIsSent("sent")

          })
            .catch((err: any) => {
              setError(err.message)
            })
    }
    else if (email){
      signUp(email).then(()=> {
        setIsSent("sent")
      })
    }
    else{
      setIsSent(false);
    }
  }

  const handleGoogleSignUp = () => {
    signUpWith(new GoogleAuthProvider());
  }
  const handleFacebookSignUp = () => {
    signUpWith(new FacebookAuthProvider());
  }
  const validateEmail = (email: String) => {
    console.log(email);

    let res = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/.test(String(email));

    return res;
  };


  if (currentUser == null || (currentUser && !currentUser.emailVerified)) {

    return (
      <ContactContainer id={id}>
        <Row justify="space-between" align="top">
          <Col lg={12} md={11} sm={24} xs={24}>
            {/* <Slide direction="left"> */}
              <Block title={title} content={preprocessText( content)} />
            {/* </Slide> */}
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            {/* <Slide direction="right"> */}
              {isSent ? (
                <Box gap="small" margin="50px 0px">
                  <Text>Thanks, the verifiaction email has been sent to <Text weight={900}>{email || currentUser && currentUser.email}</Text></Text>
                  {(isSent==true) && <Text size="small"> <a onClick={() => handleSendVerificationEmailButton()}>resend verification email</a></Text>}
                  {isSent=="sending"&& <Spinner/>}
                  <Text> Please make sure to <span className="underline">verify your email address</span>. <br />We will be contacting you with further info shortly.</Text>
                  <Text size="small">It's not you? Please <a href="" onClick={() => logout()}>click here to log out</a> and change your email.</Text>
                  {error && <Text color="red">{error.toString()}</Text>}
                </Box>
              ) :
                (
                  currentUser ?
                    (
                      <Box gap="small" margin="50px 0px">

                        <Text>Hi
                          <Text weight={900}>{" " + (currentUser.displayName ? (`${currentUser.displayName}  (${currentUser.email})`) : currentUser.email)}</Text></Text>


                        <Button onClick={() => handleSendVerificationEmailButton()} >Request early accessest access</Button>
                        <Box direction="row"><Text size="medium" weight={900} >To request access please <span className="underline">verify your email</span> address. </Text></Box>
                        <Text size="small">It's not you? Please <a href="" onClick={() => logout()}>click here to log out</a> and change your email.</Text>
                        {error && <Text color="red">{error.toString()}</Text>}
                      </Box>
                    ) : (
                      <Box>

                          <Text size="large" weight={900}>Sign up here</Text>
                        <Box pad="10px">
                          <Box direction="row" align="end" style={{maxWidth:"500px"}}>
                            <Input
                              type="text"
                              label="Enter your email"
                              name="email"
                              placeholder="Your Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value?.trim())}
                            />



                          </Box>
                          <ValidationType type="email" />
                        <Box gap="small" style={{maxWidth:"250px"}} >
                            
                            <GrommetButton onClick={() => handleSignUpButton()} icon={<BiMailSend size="20px" />} label="Sign up with email"/> 
                          <Text size="10pt" >or sign up with</Text>
                            <GrommetButton onClick={() => handleGoogleSignUp()} icon={<FcGoogle size="20px" />} label="Sign up with google"/> 
                          
                        </Box>
                        </Box>

                            {error && <Text color="red">{error.toString()}</Text>}
                      </Box>
                    )
                )}
             
              
            {/* </Slide> */}
          </Col>
        </Row>
      </ContactContainer>
    )
  }
  else {
    return (<ContactContainer id={id}>
      {/* <Slide direction="left"> */}
        <Block title={`Hi ${currentUser.displayName || currentUser.email}`} content={
          currentUser.emailVerified ?
            "You are all set in.  We will be contacting you with further info shortly." :
            "We've sent you a verification email.  We will be contacting you with further info shortly. "} />
        <Text size="small">It's not you? Or you want to change/add another email address? Please click<a href="" onClick={() => logout()}> here to log out</a>.</Text>
      {/* </Slide> */}
    </ContactContainer>)
  }



};

export default withTranslation()(SignUp);
