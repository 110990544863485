
// Import the functions you need from the SDKs you need
import { initializeApp} from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
require('firebase/auth');
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCEXIla2abiMLfy76Aynd5Xcma46P-fBIA",
  authDomain: "labelatorio-landingpage.firebaseapp.com",
  projectId: "labelatorio-landingpage",
  storageBucket: "labelatorio-landingpage.appspot.com",
  messagingSenderId: "773713574728",
  appId: "1:773713574728:web:ed8aec8f27b97b3799917d",
  measurementId: "G-01ZY15YS2T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export default app;