import { Box } from "grommet";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "../../common/Container";
import ContentBlock from "../../components/ContentBlock";
import ProgressiveImage from "../../components/ProgresiveImage";
import nodeConfigPng from "../../assets/product/node-config.png"
import Block from "../../components/Block";
import { ContentBox } from "../../components/ContentBox/ContentBox";
import nodeManagedPng from "../../assets/product/node_managed.png"
import nodeSelfHosted from "../../assets/product/node_selfHosted.png"
const Serving = () => {
    
    const navigate = useNavigate();
    const scrollTo = (id: string) => {

        const element = document.getElementById(id) as HTMLDivElement;
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
            });
        }
        else {
            setTimeout(() => {
                const element = document.getElementById(id) as HTMLDivElement;
                element && element.scrollIntoView({
                    behavior: "smooth",
                });
            }, 500)
        }
    };
    const [searchParams, setSearchParams] = useSearchParams();

    return (

        <Container>


<ContentBlock
id="training"
type="right"
title="Managed hosting"
content={"Managed hosting is the most convenient way of serving Labelator.io models.// *Deploy a new model with a single click * and forget about all the technical details"}
picture= {nodeManagedPng}

/>
            <ContentBlock
        id="training"
        type="right"
        title="Self hosted model"
        content={"You can *host models on the platform of your preference*, or even on your own infrastructure. //Just deploy the Labalator.io serving *docker container* and provide appropriate configuration. This way you can still make use of most of the managed hosting functionalities. //You can also always *download your model* and deploy it on your own. This type of deployment will work even without Labelator.io subscription. //However, you will lose the connection with Labelator.io so you won't be able to make use of the smart routing and all the human-in-the-loop (predictions review) functionalities."}
        picture= {nodeSelfHosted} 
      />

        </Container>
    )
}

export {Serving}