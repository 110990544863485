import { Row, Col } from "antd";
import { useState,useEffect } from "react";
import {Carousel,Box} from "grommet";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../../common/SvgIcon";
import { Button } from "../../../common/Button";
import { useLocation,useNavigate } from 'react-router-dom';
import { ContentBlockProps } from "../types";
import { Fade } from "react-awesome-reveal";
import {MdOutlineNavigateNext} from "react-icons/md"
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../auth/firebase";

import {
  RightBlockContainer,
  Content,
  ContentWrapper,
  ButtonWrapper,
} from "./styles";

import preprocessText from "../../../common/utils/preprocessText";

const RightBlock = ({
  title,
  content,
  button,
  picture,
  t,
  id,
  showCarouselLinks,
  carouselData,
  justBody,
  pictureSize,
  definingPictureAxis
}: ContentBlockProps) => {
  const scrollTo = (id: string) => {
    
    const element = document.getElementById(id) as HTMLDivElement;
    
    element.scrollIntoView({
      behavior: "smooth",
    });
  };
  const location = useLocation();
  let navigate = useNavigate();
  useEffect(()=>{
    if (location.hash){
      let id = location.hash.slice(1);
      if (document.getElementById(id)){
        
        scrollTo(location.hash.slice(1));
      }
    }
  },[location,]);


  const [isInView,_setIsInView] =useState(false);
  const setIsInView = (val:boolean)=>{
    
    setCardId(0);
    _setIsInView(val);
  }
  
  let imgPart:any;
  if (typeof(picture) ==="string"){
    if (picture.endsWith("svg")){
      imgPart=(<SvgIcon src={picture} height={pictureSize||"50%"} width="auto" />);
    }
    else{
      imgPart=( 
      <img 
      className="contentPicture" 
      
      src={picture} 
      style={{
        maxWidth:"900px",
        maxHeight:"900px",
          width:definingPictureAxis||"X"=="X"?pictureSize||"100%":"auto",
          height:definingPictureAxis=="Y"?pictureSize||"100%":"auto"
        }
        }
        />);
    }
  }
  else{
    imgPart=picture;
  }

  
  const [cartId, _setCardId] = useState(0);
  const setCardId =(val:number)=>{
    _setCardId(val);
    logEvent(analytics,"next_card",{"title":title, "cardId":val});
  }

  let body=(
    <Row justify="space-between" align="top" >
          <Col lg={11}  xs={22}>
            <Box pad="10px 0vh">
              <h6>{preprocessText(title,t)}</h6>
               {preprocessText(content,t)}
               {(carouselData) && !showCarouselLinks && <a onClick={()=>setCardId(cartId+1)}><Box align="end" direction="row">Read more<MdOutlineNavigateNext size="20pt"/></Box> </a>}
               {(carouselData) && showCarouselLinks  && Object.getOwnPropertyNames(carouselData).map(carouselCardName=>(
                  <a key={carouselCardName} onClick={()=>setCardId(cartId+1)}><Box align="end" direction="row"><MdOutlineNavigateNext size="20pt"/>{ carouselCardName}</Box> </a>
               )) }
              <ButtonWrapper>
                <Box direction="row" flex="grow" pad="20px 0px 0px">
                {typeof button === "object" &&
                  button.map((item: any, id: number) => {
                    return (
                      <Button
                        key={id}
                        color={item.color}
                        fixedWidth={true}
                        onClick={() =>{
                          (item.action ? item.action() : scrollTo(item.target));
                        } }
                      >
                        {t(item.title)}
                      </Button>
                    );
                  })}
                  </Box>
              </ButtonWrapper>
            </Box>
          </Col>
          <Col lg={13} xs={23} >
            <Box align="center">
            {imgPart}
            </Box>
          </Col>
        </Row>
  );
  if (justBody) return (body);
  if (carouselData){
    body = (<Carousel className="carousel" alignSelf="center" activeChild={cartId} initialChild={0} onChild={(i)=>setCardId(i)}>
      <div key={0} className="carouselCard" >{body}</div>
      {Object.getOwnPropertyNames(carouselData).map((carouselId,i)=><div key={i+1} className="carouselCard" >{carouselData[carouselId]}</div>)}
      </Carousel>
    );
  }


  return (
    <Box  id={id} pad="20px 0px" > 
      
    <RightBlockContainer className={isInView?"revealed":""}>
      <div className="carouselCard" >{body}</div>
    </RightBlockContainer>
      
      </Box>
  );
};

export default withTranslation()(RightBlock);
