import { Col, Row } from "antd"
import {Box, Stack} from "grommet"
import blob_r1 from "../../assets/blob_r1.png"
import blob_l1 from "../../assets/blob_l1.png"
import "./styles.css"

const ContentBox = ({img, type,imgPadding, children, blobBackground=true}:
    {
        img?:any,
        type:"left"|"right"
        imgPadding?:string|object,
        children?:any
        blobBackground?:boolean
    }
)=>{

const content = ( 
    <Col lg={12}  sm={20} xs={22}>
        <Box className="colContent" align="center" justify="center" fill pad="5%">
            {children}

        </Box>
    </Col>)

const picture = ( <Col lg={12}  sm={24} xs={24} >
                <Stack className="picColContent">
                {blobBackground&&
                    <Box className={type=="left"?"blobImgWrapperLeft":"blobImgWrapperRight"} justify="center" >
                        <img className="blobImg" src={type=="right"?blob_r1:blob_l1}/>
                    </Box>}
                    <Box  pad={imgPadding}>
                        {img}
                    </Box>
                    
                </Stack>
            </Col>)

    return (
        <Box>
            {type=="right"?
            <Row justify="end" >
                {content} {picture}
            </Row>
            :
            <Row justify="start" >
                {picture} {content}
            </Row>
            }
            </Box>
    )
}

export {ContentBox}